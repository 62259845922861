function highlight(id, tag) {

    var targetNode  = document.getElementById(id) || document.body;
    var hiliteTag   = tag || 'EM';
    var skipTags    = new RegExp("^(?:" + hiliteTag + "|SCRIPT|STYLE|FORM)$");
    var colors      = ['#fff'];//["#ff6", "#a0ffff", "#9f9", "#f99", "#f6f"];
    var wordColor   = [];
    var fontColor   = '#ff5c05';
    var colorIdx    = 0;
    var matchRegex  = '';
    var openLeft    = false;
    var openRight   = false;
    this.setMatchType = function(type) {
        switch(type) {
            case "left":
                this.openLeft = false;
                this.openRight = true;
                break;
            case "right":
                this.openLeft = true;
                this.openRight = false;
                break;
            case "open":
                this.openLeft = this.openRight = true;
                break;
            default:
                this.openLeft = this.openRight = false;
        }
    };

    this.setSkipTags = function(inputTag) {
        skipTags = new RegExp("^(?:" + hiliteTag + "|SCRIPT|STYLE|FORM|" +inputTag+")$");
    };

    this.setColor = function(inputColor) {
        colors = inputColor;
    }

    this.setRegex = function(input) {
        //REGEX to replace multiple Spaces with a single space
        input = input.replace(/ +/g, " ");
        input = input.split(' '); //split Keyword to array

        input = this.checkCompare(input);

        input = input.sort(function(a, b) {
            return b.length - a.length; // ASC -> a - b; DESC -> b - a
        }).join('|');
       
        var re_dot = /(\.)/i;
        var re_start_character_is_number = /^[0-9]/i;
        var re = "(\\" + input + ")";
        var match; 
        var symbol = [ "*", "+", "(", ")" ];
        
        if (!this.openLeft) re = "\\b" + re;
        
        if (!this.openRight) re = re + "\\b";

        if ((match = re_dot.exec(input)) !== null) {
        
           var spacial_symbol = input.replace(/\./g,'\\.');
           matchRegex = new RegExp("(" + spacial_symbol + ")", "i"); 

        }

        else {

            if ((match = re_start_character_is_number.exec(input)) !== null) {

                re = "(" + input + ")";
                matchRegex = new RegExp(re, "i");
            }
            
            var re_is_character = /[a-zA-Z]+/;
            if ((match = re_is_character.exec(input)) !== null) {

                re = "(" + input + ")";
                matchRegex = new RegExp(re, "i");
            }

            else {

                matchRegex = new RegExp(re, "i");
                symbol.forEach(function(entry) {
                spacial_symbol = new RegExp("\\"+ entry+"", "i");
                    if ((match = spacial_symbol.exec(input)) !== null) {
                        spacial_symbol = input.replace(spacial_symbol, "\\" + entry);
                        matchRegex = new RegExp(spacial_symbol, "i");
                    }  
                });
                
            }
        } 
    };

    this.checkCompare = function(input) {
        var k = 0;
        var sizeOfArray = input.length;
        for (i=0;i<sizeOfArray;i++) {
            for (j=0;j<sizeOfArray;j++) {
                if (i != j) {
                    if (input[i].length >= input[j].length) {
                        k =  input[j].length;
                    } else {
                        k = input[i].length;
                    }
                    for (l = 1; l <= k; l++) {
                        var string1 = '';
                        var string2 = '';

                        string1 = input[i].substring(input[i].length-l);
                        string2 = input[j].substring(0,l);

                        if (string1 == string2) {
                            var newString = '';
                            var strRight = input[j].substring(l);
                            newString = input[i] + '' + strRight;

                            if (newString != input[i] && newString != input[j]) {
                                input.push(newString);
                            }
                        }
                    }
                }
            }
        }
        return input;
    };

    this.getRegex = function() {
        var retval = matchRegex.toString();
        retval = retval.replace(/(^\/(\\b)?|\(|\)|(\\b)?\/i$)/g, "");
        retval = retval.replace(/\|/g, " ");
        return retval;
    };

    // recursively apply word highlighting
    this.hiliteWords = function(node) {
        if (node == undefined || !node) return;
        if (!matchRegex) return;
        if (skipTags.test(node.nodeName)) return;

        if (node.hasChildNodes()) {
            for(var i = 0; i < node.childNodes.length; i++) {
                this.hiliteWords(node.childNodes[i]);
            }
        }
        if (node.nodeType == 3) { // NODE_TEXT
            if ((nv = node.nodeValue) && (regs = matchRegex.exec(nv))) {
                if (!wordColor[regs[0].toLowerCase()]) {
                    wordColor[regs[0].toLowerCase()] = colors[colorIdx++ % colors.length];
                }

                var match = document.createElement(hiliteTag);
                match.appendChild(document.createTextNode(regs[0]));
                match.style.backgroundColor = wordColor[regs[0].toLowerCase()];
                match.style.fontStyle       = 'normal';
                match.style.color           = fontColor;
                //match.style.display         = 'inline-block';
                // match.style.padding         = '0px 2px 0px 0px';
                var after       = node.splitText(regs.index);
                after.nodeValue = after.nodeValue.substring(regs[0].length);
                node.parentNode.insertBefore(match, after);
            }
        };
    };

    // remove highlighting
    this.remove = function(node) {
        var arr = node.getElementsByTagName(hiliteTag);
        while (arr.length && (el = arr[0])) {
            var parent = el.parentNode;
            parent.replaceChild(el.firstChild, el);
            parent.normalize();
        }
    };

    // start highlighting at target node
    this.apply = function(input) {

       var re_power_question = /([?^])/g; 
       if (!re_power_question.test(input)) {
      
            if (input == '') {
                this.remove(targetNode);
            }

            input = input.trim();
        
            if (input == undefined || !input) return;
            this.remove(targetNode);
            this.setRegex(input);
            this.hiliteWords(targetNode);
        }
    };
}

var copyBtn = $('.copy-to-clipboard');
var client = new ZeroClipboard(copyBtn);

client.on('error', function(event) {
    copyBtn.parent('span').remove();
    ZeroClipboard.destroy();
});

// Fix hover in IE
function hoverIn(selector) {
    selector = selector.parentNode.parentNode.id;
    $('#' + selector + ' .copy-to-clipboard').addClass('zeroclipboard-is-hover');
}

function hoverOut(selector) {
    selector = selector.parentNode.parentNode.id;
     $('#' + selector + ' .copy-to-clipboard').removeClass('zeroclipboard-is-hover');
}
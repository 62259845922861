$(function() {
    // Count on click social share icon
    $('.caption-social-share > .social-share-icon').click(function(event) {
        var obj = $(this);

        // !copy link button OR (is copy link button AND toggle open copy link box)
        if (!obj.hasClass('copy-link-icon') || (obj.hasClass('copy-link-icon') && obj.find('.copy-link-active').length) && !($(event.target).parents('.copy-link-box').length)) {
            var url = obj.parent().attr('data-url');

            $.ajax({
                url: url,
                type: 'POST',
                success: function(data) {
                    $('.social-share-count').text(data.count_share);
                },
                error: function(e) {
                    console.log(e.statusText);
                }
            }); //end of ajax
        }
    });
});
var firstHover = true;
// Scroll totop button
$(window).scroll(function () {
    if ($(this).scrollTop() > 500) {
        $('#backToTop').css({ bottom: '15px' });
    } else {
        $('#backToTop').css({ bottom: '-500px' });
    }
});

var followUsBox = $('.navbar-follow-us-1');
var followUsMenu = $('.li-follow-us-1');
var btnSearchBox = $('#btn-search');
var formSearchBox = $('.search-box');
var btnSearchBoxMobile = $('#btn-search-mobile');
var formSearchBoxMobile = $('.search-box-mobile');
var searchMobile = $('#searchMobile');
var btnCopyLink = $('.copy-link');
var copyLinkBox = $('.copy-link-box');
var copyLinkText = $('.copy-link-text');
var captionSocialShare1 = $('#caption-social-share-1');
var captionSocialShare2 = $('#caption-social-share-2');

function hideFollowBox() {
    followUsBox.hide();
    followUsMenu.removeClass('active-menu-top');
    followUsMenu.find('a').addClass('follow-us').removeClass('active-follow-us');
}

// Clear State Follow Us box
function clearActiveFollowBox() {
    $('.follow-social-ul li.active').removeClass('active');
    $('.subscribe-header .subscribe-message').hide();
}

// init Follow Us box
function initFollowUsBox() {
    var followUsBox = $('.navbar-follow-us-1');

    clearActiveFollowBox();
    followUsBox.find('i.show-facebook').addClass('fa-active');
    followUsBox.find('li.facebook').addClass('active');

    // load script social for first click
    if (firstHover) {
        $.getScript('https://apis.google.com/js/platform.js');
        $.getScript('https://platform.linkedin.com/in.js');
        $.getScript('https://platform.twitter.com/widgets.js');
        $.getScript('//assets.pinterest.com/js/pinit.js');
        firstHover = false;
    }
}

function backToTop() {
    $('html, body').animate({ scrollTop: '0px' }, 800);
}


//Search Box
function hideSearchBox() {
    $('.li-search').removeClass('active-menu-top');
    formSearchBox.css('display', 'none');
    btnSearchBox.removeClass('active-search');
}

//Search Box on Mobile
function hideSearchBoxMobile() {
    btnSearchBoxMobile.css('color', '#000000');
    searchMobile.collapse('hide');
}

// disable click
function handler(e) {
    e.stopPropagation();
    e.preventDefault();
}

// auto focus textbox
function focusSearch(input) {
    var len = input.val().length;
    input.focus();
    input[0].focus();
    input[0].setSelectionRange(len, len);
}

$(document).ready(function () {
    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    });

    // disable click
    if ($('html').attr('preview')) {
        document.addEventListener('click', handler, true);
    }

    $('#main').click(function (event) {
        /* Act on the event */
    });

    // on click and touchend full page
    $(document).on('click touchend', function (e) {
        // click out for hide follow box
        if (followUsBox.is(':visible')
            && !followUsBox.is(e.target)
            && followUsBox.has(e.target).length === 0
            && !$('.active-follow-us').is(e.target)
            && $('.active-follow-us').has(e.target).length === 0
        ) {
            hideFollowBox();
        }

        // click out for hide menu on mobile
        var navbar2Box = $('#bs-example-navbar-collapse-2');
        if (navbar2Box.is(':visible')
            && !navbar2Box.is(e.target)
            && navbar2Box.has(e.target).length === 0
            && !$('#btn-menu-mobile').is(e.target)
            && !$('.icon-bar').is(e.target)
            && !$('.sr-only').is(e.target)
            && !$('.btn-menu-mobile').is(e.target)
        ) {
            $('#bs-example-navbar-collapse-2').collapse('hide');
        }

        // click out for hide search box on desktop
        if (formSearchBox.is(':visible')
            && !formSearchBox.is(e.target)
            && formSearchBox.has(e.target).length === 0
            && !$('.active-search').is(e.target)
            && $('.active-search').has(e.target).length === 0
        ) {
            hideSearchBox();
        }

        // click out for hide search box on mobile
        if (searchMobile.is(':visible')
            && !formSearchBoxMobile.is(e.target)
            && formSearchBoxMobile.has(e.target).length === 0
            && !$('.active-search-mobile').is(e.target)
            && !btnSearchBoxMobile.is(e.target)
            && !$('.glyphicon-search').is(e.target)
            && !$('.btn-menu-mobile').is(e.target)
        ) {
            hideSearchBoxMobile();
        }

        // console.log($(e.target).parents('.copy-link-box').length);
        // console.log(copyLinkBox.is(':visible'));
        if (copyLinkBox.is(':visible')
            && !$(btnCopyLink).is(e.target)
            && $(e.target).parents('.copy-link-box').length === 0) {
            // console.log('sss');
            clearCopyLinkBox();
        }

    });

    // click for show follow box
    $('.li-follow-us-1 > a.follow-us').click(function (event) {

        $('.follow-social-icon .fa').removeClass('fa-active');
        $('.follow-social-icon .fa show-facebook').addClass('fa-active');
        event.preventDefault();
        $('.subscribe-header-error').hide();

        // toggle follow box
        if (!followUsBox.is(':visible')) {
            var windowwidth = $(window).width();
            var careawidth = $('.content-area').width();
            var rightfollowus = (windowwidth - careawidth) / 2;
            var socialshow;
            var icon;
            var leftsearch = $('.li-search').width();

            followUsMenu.addClass('active-menu-top');
            followUsMenu.find('a').addClass('active-follow-us').removeClass('follow-us');
            followUsBox.css('right', rightfollowus).show();
            navbar_follow_us_1 = 1;
            initFollowUsBox();

            // Set social icon to active status when mouseover
            $('.navbar-follow-us-1 .follow-social-icon i.fa').hover(
                function () {
                    // active social icon
                    $(this).parents('.follow-social-icon').find('i.fa').removeClass('fa-active');
                    $(this).addClass('fa-active');

                    if ($(this).hasClass('show-subscribe')) {
                        $('.subscribe-header .subscribe-message').hide();
                    }

                    // active social box
                    clearActiveFollowBox();
                    socialshow = $(this).attr('att');
                    $('.follow-social-ul .' + socialshow).addClass('active');
                }
            );
            // [END] Set social icon to active status
        } else {
            hideFollowBox();
        }
    });

    // Search box Desktop
    $('#btn-search').click(function (event) {
        console.log('#btn-search')
        event.preventDefault();

        if (!formSearchBox.is(':visible')) {
            $('.li-search').addClass('active-menu-top');
            btnSearchBox.addClass('active-search');
            var windowwidth = $(window).width();
            var careawidth = $('.content-area').width();
            var rightfollowus = (windowwidth - careawidth) / 2;
            formSearchBox.css('right', rightfollowus).show();

            if ($('body').attr('browser') != "IE") {
                focusSearch($('#input-search'));
            }
        } else {
            hideSearchBox();
        }
    });

    // Search box Mobile
    $('#btn-search-mobile').click(function (event) {
        $('#input-search-mobile').focus();
        event.preventDefault();
        if (!formSearchBoxMobile.is(':visible')) {
            // btnSearchBoxMobile.css('color', '#555');

            if ($('body').attr('browser') != "IE") {
                setTimeout(function () {
                    focusSearch($('#input-search-mobile'))
                }, 500);
            }
        } else {
            btnSearchBoxMobile.css('color', '#000000');
            hideSearchBoxMobile();
        }
    });

    // Copy link
    $('.copy-link-icon > .copy-link').click(function (event) {
        event.preventDefault();

        copyBtn = $(this);

        // clear box when copy link box visibled.
        if (copyLinkBox.not(copyBtn.parents('.copy-link-icon').children('.copy-link-box')).is(':visible')) {
            clearCopyLinkBox();
        }

        copyBtn.toggleClass('fa-active copy-link-active copy-link-active-mobile');
        copyBtn.parents('.copy-link-icon').children('.copy-link-box').toggle(0, function () {
            if ($('body').attr('device-desktop') == "true") {
                $(this).children('input.copy-link-text').select();
            }
        });
    });

    $('#search_box, #search_box_mobile').submit(function () {
        $.ajax({
            url: $(this).attr('stat_search'),
            type: 'GET',
            data: {
                keyword: $(this).find('.txt-search').val()
            },
            complete: function (result) {
                $(this).submit();
            }
        });
    });

});

var checkOSName = function () {
    var OSName = 'Unknown OS';
    if (navigator.appVersion.indexOf('Win') != -1) OSName = 'Windows';
    if (navigator.appVersion.indexOf('Mac') != -1) OSName = 'MacOS';
    if (navigator.appVersion.indexOf('X11') != -1) OSName = 'UNIX';
    if (navigator.appVersion.indexOf('Linux') != -1) OSName = 'Linux';
    return OSName;
};

$(document).ready(function () {
    if (checkOSName() == 'Windows') {
        $('body').addClass('window');
    }
    // highlight menu
    var pathUrl = window.location.pathname
    if (pathUrl) {
        let eventHighlight = document.getElementsByClassName(`highlight-${pathUrl}`);
        if (eventHighlight.length > 0) {
            for (var i = 0; i < eventHighlight.length; i++) {
                eventHighlight[i].style.color = '#ED7230';
            }
        }
    }
});

/* toggle followus mobile */
function followusmoblie() {
    $('#icon-social-toggle').toggleClass('glyphicon-chevron-down glyphicon-chevron-up');
    $('#button-social-toggle').toggleClass('btn_followus_mobile_active btn_followus_mobile');
}

/* Click on the Menu Icon to transform it to "X" */
function navMenuMobile(x) {

    x.classList.toggle("change-icon");

    let box = document.getElementById('menu-navigation-mobile');

    if (box.classList.contains('visible')) {
        document.body.style.overflow = "auto";
        window.onscroll = function () { };
        box.classList.remove('visible');
        setTimeout(function () {
            box.classList.remove('visuallyhidden');
        }, 20);
    } else {
        document.body.style.overflow = "hidden";
        var x = window.scrollX;
        var y = window.scrollY;
        window.onscroll = function () { window.scrollTo(x, y); };
        box.classList.add('visuallyhidden');
        box.addEventListener('transitionend', function (e) {
            box.classList.add('visible');
        }, {
            capture: false,
            once: true,
            passive: false
        });
    }
}

let openDropdown = []
function dropdownMenuMobile(menu, dropdown) {
    let btn = document.getElementsByClassName(menu);
    let boxDropdown = document.getElementsByClassName(dropdown);
    if (boxDropdown[0]) {
        visible = document.getElementsByClassName('visible-sub-menu');
        if (openDropdown.includes(dropdown)) {
            boxDropdown[0].style.display = 'none';
            var index = openDropdown.indexOf(dropdown);
            btn[0].style.backgroundImage = 'url("/assets_reach/images/ico/chevron-down.png")';
            if (index !== -1) {
                openDropdown.splice(index, 1);
            }
        } else {
            boxDropdown[0].style.display = 'flex';
            btn[0].style.backgroundImage = 'url("/assets_reach/images/ico/chevron-up.png")';
            openDropdown.push(dropdown)
        }
    }
}
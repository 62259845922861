
//Count Social Share
$(function() {

    $("#btn-copyurl").click(function(){
        const copyText = document.querySelector("#text-url");
              copyText.focus();
              copyText.select();
              document.execCommand('copy');
              document.querySelector(".link-copied").style.display = 'block';
    })

    $("#btn-share-url").click(function(){
        if (navigator.share) {
            navigator.share({
                url: window.location.href
              });
          } else {
       document.querySelector(".link-copied").style.display = 'none';
        $("#myModalShareLink").modal()
          }
    })

    // Show modal share email content
    $('#myModalShareEmail').on('show.bs.modal', function (e) {
        $('#from_email').val('');
        $('#to_email').val('');
        $('.from_shareemail').html('');
        $('.to_shareemail').html('');
    });

    // Send Share Email
    $('#share-email').click(function() {
        $('.from_shareemail').html('');
        $('.to_shareemail').html('');
        $('#share-email').attr('data-dismiss', '');

        var obj  = $(this);
        var to   = $('#to_email').val();
        var from = $('#from_email').val();
        var content_title = $('.content_title').attr('data-value');
        var title_link    = $('.content_title').attr('data-url');
        var valid_to      = '';
        var format_from   = '';
        var format_to     = '';
        var emailerror    = 'กรุณากรอกอีเมลให้ถูกต้อง';
        var emailempty    = 'กรุณากรอกอีเมล';
        from = replaceSpaceEmail(from);
        to   = replaceSpaceEmail(to);

        // check to email empty
        if (to == '') {
            $('.to_shareemail').html(emailempty);
        }

        // check from email empty
        if (from == '') {
            $('.from_shareemail').html(emailempty);
        }

        // check format to email
        if (to != '') {
            if (validateMutipleEmail(to)) {
                format_to = true;
            } else {
                $('.to_shareemail').html(emailerror);
            }
        }

        // check format from email
        if (from != '') {
            if (validateEmail(from)) {
                format_from = true;
            } else {
                $('.from_shareemail').html(emailerror);
            }
        }

        // sent email
        if (format_from && format_to) {
            // $('#share-email').attr('data-dismiss', 'modal');

            var url   = $(this).attr('data-url');
            var data  = {
                from  : $('#from_email').val(),
                to    : to,
                title : content_title,
                title_link : title_link,
            }

            //sent email with ajax
            $.ajax({
                url: url,
                type: 'POST',
                data: data,
                beforeSend: function(){
                    obj.button('loading');
                },
                success: function(data){
                    $('#to_email').val('');
                    $('#from_email').val('');
                    alert(data);
                    $('#myModalShareEmail').modal('hide');
                },
                complete: function(){
                    obj.button('reset');
                },
                error: function(){
                    alert('ไม่สามารถส่งอีเมลได้ กรุณาติดต่อผู้ดูแลระบบ');
                }
            }); //end of ajax
        }

    });
});
$(document).ready(function() {
    $('#search_box').on('change', function (e) {
        console.log(e);
    });
	/* hilight keyword */
    var keyword = $('#main-content').attr('data-value');
    if (typeof(keyword) != 'undefined') {
        var highlighter;
        highlighter = new highlight('search-lists');
        highlighter.setMatchType('open');
        highlighter.setSkipTags('SPAN');
        highlighter.apply(keyword);
    }
});
$(document).ready(function() {
  setTimeout(function() {  // set timeout 1 sec
    var bLazy = new Blazy({
      offset : 500,
      container : 'body',
      breakpoints: [
          {
              width: 420, 
              src: 'data-src-small'
          },
          {
              width: 768, 
              src: 'data-src-medium'
          },
          {
              width: 1140, 
              src: 'data-src-large'
          }
      ]
      // selector: 'img' // all images
    });
  }, 1000); 
});